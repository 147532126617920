
import { Vue, Options } from 'vue-class-component';

import dayjs from 'dayjs';
import axios, { CancelTokenSource } from 'axios';

import { Address, Ethnicity, ExternalPatientReference, ExternalPatientReferenceType, Patient } from '@/models';
import { IAccount, IOption } from '@/lib';
import { formattedNHSNumber, isNHSNumberValid } from '@/lib/helpers/nhs-number.helper';
import { isMajorPatient, getDobFromISOString, getNhsNumberFromPatient, validateName } from '@/helpers/patient.helper';

import { EthnicityService, OrganisationPatientService } from '@/services/api';

import { FEATURES, sexOptions, genderOptions, UHB_NHS_NUMBER_KEY } from '@/constants';
import MinorPatientDetailModal from '@/views/patient/components/MinorPatientDetailModal.vue';
import AddEmailAddressErrorModal from '@/views/patient/components/AddEmailAddressErrorModal.vue';
import {
  SelectInput,
  BaseIcon,
  AccountList,
  BaseButton,
  BaseModal,
  BaseSelect,
  BaseTextInput,
  PhoneNumberInput,
  BaseTextarea,
  BaseDatePicker,
  Address as AddressAutocomplete
} from '@/lib/components';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { useSessionStore } from '@/stores/session.store';
import { usePatientStore } from '@/stores/patient.store';
import { useNotificationStore } from '@/stores/notification.store';
import BasePopover from '@/lib/components/Popover/BasePopover.vue';
import PopoverButton from '@/lib/components/Popover/PopoverButton.vue';
import { HomeMonitoringPathways } from '@/lib/components/Programs/IbdProgram/constants';
import { usePathwayName } from '@/composables/ibd';

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  components: {
    AddEmailAddressErrorModal,
    PopoverButton,
    BasePopover,
    SelectInput,
    BaseIcon,
    AccountList,
    AddressAutocomplete,
    BaseDatePicker,
    BaseTextarea,
    BaseButton,
    BaseModal,
    BaseSelect,
    BaseTextInput,
    PhoneNumberInput,
    MinorPatientDetailModal
  }
})
export default class PatientDetailsPage extends Vue {
  sessionStore = useSessionStore();
  patientStore = usePatientStore();
  $customer?: string;
  patient!: Patient;
  sexOptions = sexOptions();
  genderOptions = genderOptions();
  showEditModal = false;
  showMinorPatientDetailModal = false;
  showPatientNoEmailModal = false;
  saving = false;
  selectedViewMinorPatient: Patient | null = null;
  firstName: string = this.patient.first_name;
  lastName: string = this.patient.last_name;
  dateOfBirth: string = this.patient.date_of_birth;
  sex?: string = this.patient.sex;
  gender?: string = this.patient.gender;
  contactNumber?: string = this.patient.contact_number;
  emailAddress?: string = this.patient.email;
  address?: Address = this.patient.address;
  ethnicity = this.patient.ethnicity ? this.patient.ethnicity.title : '';
  ethnicityService = new EthnicityService();
  ethnicities: Array<Ethnicity> = [];
  errors: { [key: string]: string[] } = {};
  request: CancelTokenSource | null = null;
  referenceId = '';
  selectedPathway = '';
  selectedExternalReferenceTypeId = '';
  showAccountModal = false;
  externalPatientReferences: Array<ExternalPatientReference> = [];
  accounts: Array<IAccount> = [];
  nhsNumberLabel = 'uhb-nhs-number';
  nhsNumberKey: string = UHB_NHS_NUMBER_KEY;
  isPatientPhoneOptional = !isFeatureFlagEnabled(FEATURES.PATIENT_PHONE_MANDATORY);
  isPatientAddressOptional = isFeatureFlagEnabled(FEATURES.PATIENT_ADDRESS_OPTIONAL);
  IBDPathwaysFeatureFlag = isFeatureFlagEnabled(FEATURES.IBD_PATHWAYS);
  isPatientEmailOptional = !isFeatureFlagEnabled(FEATURES.PATIENT_EMAIL_MANDATORY);
  notificationStore = useNotificationStore();
  HomeMonitoringPathwaysEnum = HomeMonitoringPathways;

  get service(): OrganisationPatientService {
    return new OrganisationPatientService(this.organisationId);
  }

  get isNotEnrolledInCopdProgram(): boolean {
    return (
      !this.patient.copd_home_monitoring_program?.id ||
      !!this.patient.copd_home_monitoring_program?.discharged_program_review_id
    );
  }

  get isMajorPatient(): boolean {
    return isMajorPatient(this.patient);
  }

  get externalPatientReferenceTypes(): Array<ExternalPatientReferenceType> {
    return this.patientStore.externalPatientReferenceTypes;
  }

  get patientDob(): string {
    return dayjs.utc(this.dateOfBirth.substring(0, 23)).format('YYYY-MM-DD');
  }

  set patientDob(value) {
    this.dateOfBirth = value;
  }

  get patientSex() {
    return this.sexOptions.find((option) => option.value === this.sex);
  }

  get patientGender() {
    return this.genderOptions.find((option) => option.value === this.gender);
  }

  get isAccountItemListButtonHidden(): boolean {
    return this.accounts.every((a) => a.value) && this.accounts.length === this.externalPatientReferenceTypes.length;
  }

  get externalPatientReferenceTypeOptions() {
    return this.externalPatientReferenceTypes
      .filter(
        (externalPatientReferenceType) =>
          !this.accounts.find((account) => account.typeId === externalPatientReferenceType.id && account.value)
      )
      .map((externalPatientReferenceType) => ({
        value: externalPatientReferenceType.id,
        label: externalPatientReferenceType.key === this.nhsNumberKey
          ? this.$t('custom.uhb.patient.nhs-number')
          : externalPatientReferenceType.label
      }));
  }

  get selectedExternalReferenceTypeKey(): string {
    return this.selectedExternalReferenceType ? this.selectedExternalReferenceType.key : '';
  }

  get selectedExternalReferenceTypeLabel(): string {
    return this.selectedExternalReferenceType ? this.selectedExternalReferenceType.label : '';
  }

  get selectedExternalReferenceType(): ExternalPatientReferenceType | undefined {
    return this.getExternalReferenceType(this.selectedExternalReferenceTypeId);
  }

  get disableSaveChangesOnAccountModal(): boolean {
    if (this.selectedExternalReferenceTypeKey === this.nhsNumberKey) {
      return !isNHSNumberValid(this.referenceId);
    }
    return !this.selectedExternalReferenceTypeId.length || !this.referenceId.length;
  }

  get organisationId() {
    return this.$route.params.organisationId;
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  get ethnicityOptions(): IOption[] {
    return this.ethnicities.map((ethnicity) => ({
      value: ethnicity.id,
      label: ethnicity.title
    }));
  }

  get usesGender() {
    return this.sessionStore.currentOrganisation?.uses_gender;
  }

  get minorPatientMrn() {
    if (this.selectedViewMinorPatient) {
      return this.selectedViewMinorPatient.mrn;
    }
    return null;
  }

  get minorPatientMergedDate() {
    if (this.selectedViewMinorPatient?.merged_at) {
      return this.formatMergeDate(this.selectedViewMinorPatient.merged_at);
    }
    return null;
  }

  getGenderOrSex(minorPatient: Patient) {
    const sexLabel = this.$t('platform.patient.sex') as string;
    const genderLabel = this.$t('platform.patient.gender') as string;
    let label,
      value: string | undefined = '';

    if (minorPatient.sex && (!this.usesGender || !minorPatient.gender)) {
      label = sexLabel;
      value = this.getSex(minorPatient.sex);
    } else if (minorPatient.gender && (this.usesGender || !minorPatient.sex)) {
      label = genderLabel;
      value = this.getGender(minorPatient.gender);
    }
    return { label, value };
  }

  get minorPatientDetails() {
    if (this.selectedViewMinorPatient) {
      const sexOrGender = this.getGenderOrSex(this.selectedViewMinorPatient);
      return [
        {
          label: this.$t('custom.uhb.patient.nhs-number'),
          value: getNhsNumberFromPatient(this.selectedViewMinorPatient)
        },
        {
          label: this.$t('platform.patient.date-of-birth'),
          value: this.selectedViewMinorPatient.date_of_birth
            ? this.$d(getDobFromISOString(this.selectedViewMinorPatient.date_of_birth), 'short')
            : ''
        },
        {
          label: this.$t('platform.patient.given-names'),
          value: this.selectedViewMinorPatient.first_name
        },
        {
          label: this.$t('platform.patient.family-name'),
          value: this.selectedViewMinorPatient.last_name
        },
        {
          label: sexOrGender.label,
          value: sexOrGender.value
        },
        {
          label: this.$t('platform.patient.ethnicity'),
          value: this.selectedViewMinorPatient.ethnicity
            ? this.selectedViewMinorPatient.ethnicity.title
            : this.$t('platform.patient.not-specified')
        },
        {
          label: this.$t('platform.patient.email-address'),
          value: this.selectedViewMinorPatient.email
        },
        {
          label: this.$t('platform.patient.contact-number'),
          value: this.selectedViewMinorPatient.contact_number
        },
        {
          label: this.$t('custom.uhb.patient.interpreter-required'),
          value: this.selectedViewMinorPatient.interpreter_required ? 'Yes' : 'No'
        },
        {
          label: this.$t('platform.patient.address'),
          value: this.selectedViewMinorPatient.address ? this.selectedViewMinorPatient.address : ''
        }
      ];
    }
    return null;
  }

  formatDate(date: string | null, type = 'short'): string {
    return date ? this.$d(getDobFromISOString(date), type) : '';
  }

  formatMergeDate(date: string): string {
    return date ? this.$d(new Date(date), 'shortMonth') : '';
  }

  async created() {
    this.hydrateAccountsFromPatient();
    await this.fetchEthnicities();
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  validatePatientName(name: string, isLastName = false) {
    if (!validateName(name)) {
      this.errors = {
        ...this.errors,
        ...(isLastName ? {
          last_name: [this.$t('platform.error.last-name-error') as string]
        } : {
          first_name: [this.$t('platform.error.first-name-error') as string]
        })
      };
    } else if (isLastName && this.errors.last_name?.length) {
      delete this.errors.last_name;
    } else if (!isLastName && this.errors.first_name?.length) {
      delete this.errors.first_name;
    }
  }

  async fetchEthnicities() {
    try {
      this.request = axios.CancelToken.source();
      this.ethnicities = (
        await this.ethnicityService.index({
          cancelToken: this.request.token
        })
      ).data;
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.fetch-data')
        });
      }
    } finally {
      this.request = null;
    }
  }

  edit() {
    this.errors = {};
    this.showEditModal = true;
  }

  showEditAndFocusEmail() {
    this.showPatientNoEmailModal = false;
    this.showEditModal = true;
    this.$nextTick(() => this.$refs.emailTemplateRef?.focus());
  }

  enrolPatientToHomeMonitoring(pathway: HomeMonitoringPathways) {
    if (pathway !== HomeMonitoringPathways.COPD && !this.patient.email) {
      this.selectedPathway = usePathwayName(pathway);
      this.showPatientNoEmailModal = true;
      return;
    }

    this.$router.push({
      name: 'virtual-ward-enrolment',
      params: {
        organisationId: this.organisationId,
        patientId: this.patient.id
      },
      query: {
        pathway
      }
    });
  }

  async save() {
    this.saving = true;

    try {
      this.externalPatientReferences = this.accounts.map((account) => ({
        external_patient_reference_type_id: account.typeId,
        value: account.value as string
      }));

      const selectedEthnicity = this.ethnicities.find((ethnicity) => ethnicity.id === this.ethnicity);
      const response = await this.service.update(this.patient.id, {
        first_name: this.firstName,
        last_name: this.lastName,
        date_of_birth: this.dateOfBirth,
        sex: this.sex,
        gender: this.gender,
        contact_number: this.contactNumber,
        email: this.emailAddress,
        address: this.address,
        ethnicity_id: selectedEthnicity?.id ?? null,
        external_patient_references: this.externalPatientReferences
      });

      this.patient.external_patient_references = response.external_patient_references;
      this.hydrateAccountsFromPatient();

      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.patient.updated')
      });

      this.$emit('updated', response);
      this.showEditModal = false;
    } catch (e) {
      if (e.response.status === 422) {
        this.errors = e.response.data.errors;
      } else {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.saving-data')
        });
      }
    } finally {
      this.saving = false;
    }
  }

  cancel() {
    this.showEditModal = false;
    this.firstName = this.patient.first_name;
    this.lastName = this.patient.last_name;
    this.dateOfBirth = this.patient.date_of_birth;
    this.sex = this.patient.sex;
    this.gender = this.patient.gender;
    this.contactNumber = this.patient.contact_number;
    this.emailAddress = this.patient.email;
    this.address = this.patient.address;
    this.ethnicity = this.patient.ethnicity ? this.patient.ethnicity.title : '';
  }

  formatNHSNumber(input: string): string {
    return formattedNHSNumber(input);
  }

  validateNHSNumberOnBlur() {
    this.referenceId = formattedNHSNumber(this.referenceId);
    this.validateNHSNumber();
  }

  validateNHSNumber() {
    if (!isNHSNumberValid(this.referenceId)) {
      this.errors = {
        ...this.errors,
        referenceId: [this.$t('custom.uhb.patient.nhs-number-error') as string]
      };
    } else if (this.errors.referenceId) {
      this.errors = {
        ...this.errors,
        referenceId: []
      };
    }
  }

  getExternalReferenceType(id: string) {
    return this.externalPatientReferenceTypes.find((reference) => reference.id === id);
  }

  deleteAccount(account: IAccount) {
    this.accounts = this.accounts.map((a) => {
      if (a.typeId === account.typeId) {
        a.value = null;
      }
      return a;
    });
  }

  editAccount(account: IAccount) {
    this.selectedExternalReferenceTypeId = account.typeId;
    this.referenceId = account.value as string;
    this.showAccountModal = true;
  }

  closeAccountModal() {
    this.showAccountModal = false;
    this.selectedExternalReferenceTypeId = '';
    this.referenceId = '';
  }

  hydrateAccountsFromPatient() {
    if (this.patient.external_patient_references) {
      this.accounts = this.patient.external_patient_references.map(
        (externalPatientReference: ExternalPatientReference): IAccount => ({
          label: externalPatientReference.type ? externalPatientReference.type.label : '',
          value: externalPatientReference.value,
          typeId: externalPatientReference.type ? externalPatientReference.type.id : ''
        })
      );
    }
  }

  viewMinorPatient(patient: Patient) {
    this.showMinorPatientDetailModal = true;
    this.selectedViewMinorPatient = patient;
  }

  closeMinorPatientDetailModal() {
    this.showMinorPatientDetailModal = false;
    this.selectedViewMinorPatient = null;
  }

  getGender(gender: string) {
    const genderOption = this.genderOptions.find((option) => option.value === gender);
    return genderOption ? genderOption.label : gender;
  }

  getSex(sex: string) {
    const sexOption = this.sexOptions.find((option) => option.value === sex);
    return sexOption ? sexOption.label : sex;
  }

  saveAccountModal() {
    try {
      if (this.referenceId && this.referenceId.length) {
        const referenceType = this.getExternalReferenceType(this.selectedExternalReferenceTypeId);
        if (referenceType) {
          const account: IAccount = {
            label: referenceType.label,
            typeId: referenceType.id,
            value: this.referenceId
          };

          const index = this.accounts.findIndex((a) => a.typeId === account.typeId);
          const accounts = [...this.accounts];
          if (index >= 0) {
            accounts[index] = account;
          } else {
            accounts.push(account);
          }
          this.accounts = accounts;
        }
      }
      this.closeAccountModal();
    } catch (error) {
      this.errors = {
        ...this.errors,
        account: [error.message]
      };
    }
  }
}
