import { HomeMonitoringPathways } from '@/lib/components/Programs/IbdProgram/constants';
import { i18n } from '@/i18n/i18n';
const { t } = i18n.global;

export const usePathwayName = (pathway = ''): string => {
  switch (pathway) {
  case HomeMonitoringPathways.COPD:
    return t('custom.uhb.copd.name');
  case HomeMonitoringPathways.CrohnsStable:
    return t('custom.uhb.ibd.crohns-disease-stable');
  case HomeMonitoringPathways.CrohnsFlare:
    return t('custom.uhb.ibd.crohns-disease-flare');
  case HomeMonitoringPathways.UlcerativeStable:
    return t('custom.uhb.ibd.ulcerative-colitis-stable');
  case HomeMonitoringPathways.UlcerativeFlare:
    return t('custom.uhb.ibd.ulcerative-colitis-flare');
  default:
    return '';
  }
};
